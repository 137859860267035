<template>
  <base-layout>
    <div class="block full">
      <div class="block-title">
        <h2>Sign In Detail</h2>
      </div>

      <sign-in-detail-list></sign-in-detail-list>
    </div>
  </base-layout>
</template>

<script>
import SignInDetailList from "@/components/signInDetail/ListItem";
import BaseLayout from "./layouts/Base";

export default {
  name: "SignInDetail",
  components: {
    BaseLayout,
    SignInDetailList
  }
};
</script>
