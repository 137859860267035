<template>
  <div>
    <div class="block-section">
      <div class="row">
        <div class="form-group col-md-2">
          <datepicker
            v-model="fromDate"
            value-type="format"
            input-class="form-control"
            placeholder="From"
            type="datetime"
          ></datepicker>
        </div>
        <div class="form-group col-md-2">
          <datepicker
            v-model="toDate"
            value-type="format"
            input-class="form-control"
            placeholder="To"
            type="datetime"
          ></datepicker>
        </div>
        <div class="form-group col-md-2">
          <button type="button" class="btn btn-primary" @click="handleSearch">
            <i class="fa fa-search"></i> Search
          </button>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table
        class="table table-vcenter table-bordered table-hover table-striped table-condensed"
        v-if="hasItem"
      >
        <thead>
          <tr>
            <th>ID</th>
            <th>Login by IP</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="report in historyList">
            <td>{{ report.id }}</td>
            <td>{{ report.ip }}</td>
            <td>{{ report.datetime }}</td>
          </tr>
        </tbody>
        <tr v-if="totalPage > 1">
          <td colspan="5" class="text-right">
            <paginate
              v-model="page"
              :page-count="totalPage"
              :prev-text="'Prev'"
              :next-text="'Next'"
              :container-class="'pagination'"
              :page-class="'page-item'"
            ></paginate>
          </td>
        </tr>
      </table>

      <div v-else class="block-section">No sign in detail</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SignInReportListItem",
  data: () => ({
    page: 1,
    fromDate: null,
    toDate: null
  }),
  created() {
    this.fetchReport();
  },
  computed: {
    ...mapGetters({
      reports: "wallet/History"
    }),
    totalPage() {
      if (this.reports && this.reports.length) {
        return Math.floor(this.reports.length / 15) + 1;
      }
    },
    historyList() {
      if (this.reports && this.reports.length) {
        return this.reports.slice((this.page - 1) * 15, this.page * 15);
      }
    },
    hasItem() {
      if (this.reports && this.reports.length) {
        return this.historyList?.length > 0;
      }
    }
  },
  methods: {
    async fetchReport() {
      this.$store.dispatch("wallet/req_getHistoryLogin", {
        page: this.page,
        fromDate: this.fromDate,
        toDate: this.toDate
      });
    },
    handleSearch() {
      this.fetchReport();
    }
  }
};
</script>
